import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './index.css'; // Ensure this imports your CSS file

function App() {
  useEffect(() => {
    document.title = "Fahad Baig";

    const handleMouseMove = (e) => {
      const glowElement = document.querySelector(".cursor-glow");
      const posX = e.clientX;
      const posY = e.clientY;

      // Update radial-gradient position and color based on mouse coordinates | Dark
      // glowElement.style.background = `radial-gradient(600px at ${posX}px ${posY}px, rgba(1, 1, 1, 0.15), transparent 80%)`;

      // Uncomment for Light theme
      glowElement.style.background = `radial-gradient(600px at ${posX}px ${posY}px, rgba(51 ,51 , 77 , 0.3), transparent 80%)`;

      // Uncomment for dual radial-gradients with slight offset
      // glowElement.style.background = `radial-gradient(600px at ${posX}px ${posY}px, rgba(29, 78, 216, 0.15), transparent 80%), radial-gradient(600px at ${posX + 150}px ${posY + 150}px, rgba(255, 0, 0, 0.15), transparent 80%)`;
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Router>
      <div className="cursor-glow"></div>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;