import ExperienceCard from './ExperienceCard';
import React, { useEffect } from 'react';

const Experience = () => {

    const experience = [{
        date: '2024 — PRESENT',
        title: 'MLOps Engineer L4 · Motive',
        description: 'Design and development of high volume and high availability inference workloads using Kubernetes and NVIDIA Triton. \
          Work closely with RnD teams to understand model requirements, processing pipelines and infrastructure constraints.\
          Work with cross-functional teams to design model training, testing and tracking pipelines using Kubeflow and MLFlow.',
        tags: ['Kubernetes', 'Triton', 'Python', 'PyTorch', 'TensorFlow', 'Kubeflow', 'MLFlow', 'Flask', 'Docker'],
        url: 'https://gomotive.com/'
        },
        {date: '2024 — PRESENT',
        title: 'Python Developer (Contractor) · Turing',
        description: 'Work with one of the largest AI firm to drive forward the next generation of Multimodal LLMs. \
          Bridge the gap between researchers and data procurement teams to ensure correctness and reliability of the data, hence improving model performance and reducing costs. \
          Build data pipelines and data models for in-depth analysis and experimentation.',
        tags: ['Python', 'Machine Learning', 'Multimodal LLMs', 'Leadership'],
        url: 'https://turing.com/'
      },
        {date: 'FEB — SEP 2024',
        title: 'Full-Stack Engineer · Blackwise LLC · Toptal',
        description: 'Led the development of an MVP marketplace application for the Black community. \
        Architected backend infrastructure with FastAPI, Python, and Elasticsearch. Developed the frontend with React 18 and Bootstrap. \
        Integrated Stripe for subscriptions. Implemented JWT authentication and domain validation. \
        Built a web scraper using Selenium and NLP for indexing content. Integerated New Relic for system monitoring and logging.',
        tags: ['Python', 'FastAPI', 'Elasticsearch', 'React', 'Stripe', 'Selenium', 'spaCy', 'New Relic'],
        url: 'https://toptal.com/'
      },
        {date: '2022 — 2024',
        title: 'Senior Backend Engineer · Entertainers FZ',
        description: 'Developed RESTful microservices using Flask and SQLAlchemy for various product features. \
        Engineered a multi-tenant database system for performance enhancement. \
        Created dynamic filters for user-specific merchant searches. \
        Integrated AWS Secrets Manager for credential security. Utilized Amazon Personalize for AI-driven recommendations. \
        Designed Elasticsearch indices for search functionality. \
        Led backend migration to Python 3.11.1. Conducted code reviews and mentored developers.',
        tags: ['Python', 'Flask', 'SQLAlchemy', 'AWS Secrets Manager', 'Amazon Personalize', 'Elasticsearch', 'Microservices', 'PostgreSQL', 'MariaDB'],
        url: 'https://www.theentertainerme.com/'
      },
        {date: 'SEP — NOV 2022',
        title: 'Backend Engineer (Contractor) · Ricult Fintech',
        description: 'Led the backend design and development for Ricult\'s new Agent and Farmer applications using FastAPI, MariaDB, and Async SQLAlchemy. \
        Established development standards to reduce development time and collaborated with Product and UI/UX teams. Integrated Sentry for improved monitoring. \
        Engineered an AI model inferencing engine with Azure Batch Processing, reducing computational costs by 50%.',
        tags: ['Python', 'FastAPI', 'Async SQLAlchemy', 'Docker', 'Azure Batch Processing', 'Sentry', 'Redis', 'MariaDB'],
        url: 'https://www.web.ricult.com/home'
      },
        {date: '2020 — 2022',
        title: 'AI Engineer · CureMD',
        description: 'Engineered SmartRecruit, an AI-driven resume parsing and scoring system, using TensorFlow, PyTorch, and Flask. \
        Deployed the solution on AWS ECS and Fargate. Developed a multi-threaded RabbitMQ-based audio transcription system with NVIDIA Nemo ASR. \
        Led the creation of an automated Fax OCR system using Django and PostgreSQL. Developed an ML-based ID card information extraction engine using Yolo and PaddlePaddle. \
        Mentored teams and made strategic architectural decisions on AI projects.',
        tags: ['TensorFlow', 'PyTorch', 'Flask', 'RabbitMQ', 'NeMo', 'PaddlePaddle', 'Redis', 'Fission'],
        url: 'https://www.curemd.com/'
      }
    ];
  
      useEffect(() => {
        const cards = document.querySelectorAll('.experience-card-qr');
    
        const handleMouseEnter = (event) => {
          cards.forEach((card) => {
            if (card !== event.currentTarget) {
              card.classList.add('dimmed');
              card.classList.remove('nondimmed');
            }
          });
        };
    
        const handleMouseLeave = () => {
          cards.forEach((card) => {
            card.classList.remove('dimmed');
            card.classList.add('nondimmed');
          });
        };
    
        cards.forEach((card) => {
          card.addEventListener('mouseenter', handleMouseEnter);
          card.addEventListener('mouseleave', handleMouseLeave);
        });
    
        return () => {
          cards.forEach((card) => {
            card.removeEventListener('mouseenter', handleMouseEnter);
            card.removeEventListener('mouseleave', handleMouseLeave);
          });
        };
      }, []);

  return (
    <section className="p-6 mx-auto my-20 max-w-6xl font-inter text-white">
        <div className="md:text-left"></div>
        <div className="md:text-left">
        <div>
          {experience.map((experience, index) => (
            <ExperienceCard key={index} date={experience.date} title={experience.title} description={experience.description} tags={experience.tags} hover_class="experience-card-qr" url={experience.url} />
          ))}
        </div>
        </div>
    </section>
  );
};

export default Experience;