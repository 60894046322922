import SkillsCard from './SkillsCard';
import React, { useEffect } from 'react';

const Skills = () => {
   
    const skills = [
        {
            category: 'Languages',
            tags: ['Python', 'C++', 'JavaScript', 'Rust', 'SQL'],
            color: 'colorGreen'
        },
        {
            category: 'Frameworks',
            tags: ['React', 'Tailwind', 'Bootstrap', 'Django', 'Flask', 'OAuth 2.0', 'TensorFlow', 'PyTorch', ],
            color: 'colorMauve'
        },
        {
            category: 'Databases',
            tags: ['MySQL', 'PostgreSQL', 'MongoDB', 'Redis', 'DynamoDB', 'Elasticsearch'],
            color: 'colorPink'
        },
        {
            category: 'Tools',
            tags: ['Git', 'Jira', 'Docker', 'Kubernetes', 'OpenCV', 'Pandas', 'SQLAlchemy', 'Numpy', 'PaddlePaddle', 'Triton', 'NeMo', 'Whisper'],
            color: 'colorRosewater'
        },
        {
            category: 'Other',
            tags: ['Deep Neural Networks', 'Multimodal LLMs', 'NLP', 'ASR', 'System Design', 'Microservices', 'AWS Fargate', 'AWS EKS', 'Azure'],
            color: 'colorMaroon'
        },
        {
            category: 'Soft',
            tags: ['Agile', 'Scrum', 'Leadership', 'Teamwork', 'Communication', 'Problem Solving', 'Critical Thinking', 'Continuous Improvement'],
            color: 'colorSapphire'
        }
    ]
  
    useEffect(() => {
      const cards = document.querySelectorAll('.skills-card-qr');
  
      const handleMouseEnter = (event) => {
        cards.forEach((card) => {
          if (card !== event.currentTarget) {
            card.classList.add('dimmed');
            card.classList.remove('nondimmed');
          }
        });
      };
  
      const handleMouseLeave = () => {
        cards.forEach((card) => {
          card.classList.remove('dimmed');
          card.classList.add('nondimmed');
        });
      };
  
      cards.forEach((card) => {
        card.addEventListener('mouseenter', handleMouseEnter);
        card.addEventListener('mouseleave', handleMouseLeave);
      });
  
      return () => {
        cards.forEach((card) => {
          card.removeEventListener('mouseenter', handleMouseEnter);
          card.removeEventListener('mouseleave', handleMouseLeave);
        });
      };
    }, []);

  return (
    <section className="p-6 mx-auto my-20 max-w-6xl font-inter text-white">
        <div className="md:text-left"></div>
        <div className="md:text-left">
        <div>
          {skills.map((skill, index) => (
            <SkillsCard key={index} category={skill.category} tags={skill.tags} color={skill.color} hover_class="skills-card-qr" />
          ))}
        </div>
        </div>
    </section>
  );
};

export default Skills;