import ExperienceCard from './ExperienceCard';
import React, { useEffect } from 'react';

const Education = () => {

    const education = [{
        date: '2016 — 2020',
        title: 'Bachelor\'s in Electrical Engineering',
        other_designation: 'NUST Islamabad, Pakistan',
        description: 'CGPA: 3.71/4.00',
        tags: [],
        url: 'https://www.nust.edu.pk/'
    },
    {
        date: '2016 — 2020',
        title: 'Minor in Business Management & Administration',
        other_designation: 'NUST Islamabad, Pakistan',
        description: 'CGPA: 3.88/4.00',
        tags: [],
        url: 'https://www.nust.edu.pk/'
    }
    ];
  
      useEffect(() => {
        const cards = document.querySelectorAll('.education-card-qr');
    
        const handleMouseEnter = (event) => {
          cards.forEach((card) => {
            if (card !== event.currentTarget) {
              card.classList.add('dimmed');
              card.classList.remove('nondimmed');
            }
          });
        };
    
        const handleMouseLeave = () => {
          cards.forEach((card) => {
            card.classList.remove('dimmed');
            card.classList.add('nondimmed');
          });
        };
    
        cards.forEach((card) => {
          card.addEventListener('mouseenter', handleMouseEnter);
          card.addEventListener('mouseleave', handleMouseLeave);
        });
    
        return () => {
          cards.forEach((card) => {
            card.removeEventListener('mouseenter', handleMouseEnter);
            card.removeEventListener('mouseleave', handleMouseLeave);
          });
        };
      }, []);

  return (
    <section className="p-6 mx-auto my-20 max-w-6xl font-inter text-white">
        <div className="md:text-left"></div>
        <div className="md:text-left">
        <div>
          {education.map((education, index) => (
            <ExperienceCard key={index} date={education.date} title={education.title} description={education.description} tags={education.tags} other_designation={education.other_designation} hover_class="education-card-qr" url={education.url} />
          ))}
        </div>
        </div>
    </section>
  );
};

export default Education;