import React from 'react';
import Introduction from '../components/Introduction';
import Experience from '../components/Experience';
import About from '../components/About';
import Education from '../components/Education';
import Skills from '../components/Skills';
import NavigationMenu from '../components/NavigationMenu';
import Footer from '../components/Footer';
import SocialMediaIcons from '../components/SocialMediaIcons';
import DocumentLinks from '../components/DocumentLinks';

const Home = () => {
  return (
    <main className="bg-primaryBackground min-h-screen">
      <div className="area">
        <div className='circles'>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </div>
      </div>
      <div id="about" className="flex flex-col md:flex-row max-w-6xl mx-auto">
      <div className="md:flex-[2_2_0%]  md:fixed md:top-0 flex flex-col justify-between h-full">
          <div>
            <Introduction />
            <NavigationMenu />
          </div>
          <SocialMediaIcons />
        </div>
        <div className="md:flex-[3_3_0%]  md:ml-auto md:pl-[45%]">
          <section id="about">
            <About />
          </section>
          <section id="experience">
            <Experience />
          </section>
          <section id="education">
            <Education />
          </section>
          <section id="skills">
            <Skills />
          </section>
          <section>
            <div className='flex justify-center items-center'>
            </div>
          </section>
          <DocumentLinks />
          <section className='mt-20'>
            <Footer />
          </section>
          <section>
            <div className='flex justify-center items-center'>
              <div style={{ width: '1px', height: '104px'}}></div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default Home;