import React from 'react';

const Footer = () => {
  return (
    <section className="pr-20 pl-4" style={{ position: "relative" }}>
      <p className="text-slate-500 text-xs">
        This work is inspired by the design from <a href="https://brittanychiang.com/" target="_blank" rel="noopener noreferrer">Brittany Chiang</a>
        . Built with <a href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer">Tailwind CSS</a> and <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React JS</a> and lots of ❤️.
        Color palette picked from <a href="https://github.com/catppuccin/catppuccin" target="_blank" rel="noopener noreferrer">Catppuccin Mocha</a>.
      </p>
    </section>
  );
};

export default Footer;
