import React, { useEffect, useState } from 'react';

const NavigationMenu = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let currentSection = '';

      sections.forEach((section) => {
        if (section.getAttribute('id') === null || section.getAttribute('id') === '') {
          return;
        }
          const sectionTop = section.offsetTop;
          const sectionHeight = section.clientHeight;
        if (window.scrollY >= sectionTop - 350 && window.scrollY < sectionTop + sectionHeight - 50) {
          currentSection = section.getAttribute('id');
        }
      });

      // If scrolled to the bottom always set active section to skills
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        currentSection = 'skills';
      }

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="pl-6 navigation-menu text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200 pl-5">
      <ul>
        <li className={activeSection === 'about' ? 'active' : ''}>
          <a href="#about">ABOUT</a>
        </li>
        <li className={activeSection === 'experience' ? 'active' : ''}>
          <a href="#experience">EXPERIENCE</a>
        </li>
        <li className={activeSection === 'education' ? 'active' : ''}>
          <a href="#education">EDUCATION</a>
        </li>
        <li className={activeSection === 'skills' ? 'active' : ''}>
          <a href="#skills">SKILLS</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationMenu;