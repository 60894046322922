import React from 'react';

const SkillsCard = ({ category, tags, color, hover_class}) => {
  const color_class = "bg-" + color + "10 text-" + color;
  return (
    <div className={"experience-card text-white pr-4 pl-4 mt-1 mb-1 " + hover_class}>
      <div className="pt-2 pb-2 grid grid-cols-6 gap-4">
        <div className="col-span-1">
          <span className="text-gray-400 text-xs">{category}</span>
        </div>
        <div className="col-span-5">
          <div className="flex flex-wrap">
        {tags.map((tag, index) => (
          <span key={index} className={color_class + " font-medium leading-5 px-3 py-1 mr-2 mb-2 rounded-full text-xs"}>{tag}</span>
        ))}
      </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsCard;