import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const ExperienceCard = ({ date, title, description, tags, other_designation=[], hover_class, url }) => {
  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div onClick={handleClick} className={"experience-card text-white pr-4 pl-4 mt-8 mb-8 " + hover_class} style={{ cursor: 'pointer' }}>
      <div className="pt-4 pb-4 grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <span className="text-gray-400 text-xs">{date}</span>
        </div>
        <div className="col-span-3">
          <h3 className="exp-title">{title+" "}<FaArrowRight className="text-xs arrow" /></h3>
          {other_designation.length > 0 && (
            <p className="text-gray-400 mb-3">
              {other_designation}
            </p>
          )}
          <p className="text-gray-400 mb-3 mt-3 text-sm">
            {description}
          </p>
          <div className="flex flex-wrap">
            {tags.map((tag, index) => (
              <span key={index} className="bg-colorPeach10 text-colorPeach font-medium leading-5 px-3 py-1 mr-2 mb-2 rounded-full text-xs">{tag}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;