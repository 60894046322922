import React from 'react';
import { FaEnvelope, FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa';

const SocialMediaIcons = () => {
  return (
    <div className="flex space-x-4 pl-6 text-gray-500" style={{ paddingBottom: '104px' }}>
      <a href="https://github.com/hackslashX" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300">
        <FaGithub size={25} />
      </a>
      <a href="https://linkedin.com/in/hcx-fb" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300">
        <FaLinkedin size={25} />
      </a>
      <a href="https://www.instagram.com/hackslash.baig/" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300">
        <FaInstagram size={25} />
      </a>
      <a href="mailto:fadi@fahadbaig.com" target="_blank" rel="noopener noreferrer" className="hover:text-white transition duration-300">
        <FaEnvelope size={25} />
      </a>
    </div>
  );
};

export default SocialMediaIcons;