import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const DocumentLinks = () => {
  return (
    <div className="p-6 document-links text-gray-400">
      <a href="https://personal-r2-eus.fahadbaig.com/portfolio/Resume_Muhammad_Fahad_Baig.pdf" target="_blank" rel="noopener noreferrer" className="link">
        View Full Résumé <FaArrowRight className="text-xs arrow" />
      </a>
    </div>
  );
};

export default DocumentLinks;