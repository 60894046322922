import React from 'react';

const Introduction = () => {
  return (
    <section className="p-6 my-20 max-w-6xl font-inter text-white" style={{width:"400px", position:"relative"}}>
          <h1 className="text-4xl font-bold text-slate-200 sm:text-5xl">Fahad Baig</h1> {/* Super bold */}
          <h3 className="mt-2 text-xl font-medium text-gray-300">MLOps Engineer L4</h3> {/* Lighter shade of white */}
          <h3 className="text-l font-medium text-gray-400">Senior Backend Engineer (Python)</h3> {/* Lighter shade of white */}
          <p className="mt-10 text-gray-400">Transforming AI into scalable, reliable backend solutions.</p> {/* Light gray */}
    </section>
  );
};

export default Introduction;