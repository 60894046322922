import React from 'react';

const About = () => {
  return (
    <section className="p-6 mx-auto my-20 max-w-6xl font-inter text-white" style={{position:"relative"}}>
        <div className="md:text-left">
          <p className="text-gray-400"> {/* Light text */}
          My journey in tech started with a fascination for data and its potential to <span className="text-colorGreen">drive impactful decisions</span>. 
          Over the course of 5+ years, I've honed my skills in <span className="text-colorGreen">building scalable ML pipelines</span> and <span className="text-colorGreen">robust backend systems</span>, ensuring that every model and service I deploy is both reliable and efficient.
          I have experience in a wide array of industries, from <span className="text-colorGreen">Healthcare</span> to <span className="text-colorGreen">Agriculture</span>, <span className="text-colorGreen">Consumer Rewards</span>, <span className="text-colorGreen">Search Engines</span>, <span className="text-colorGreen">Mobility</span>, and <span className="text-colorGreen">Road Safety</span>.
          </p>
          <p className="mt-5 text-gray-400"> {/* Light text */}
          I thrive on the challenges of streamlining <span className="text-colorGreen">machine learning operations</span> and developing <span className="text-colorGreen">high-performance backend architectures</span>. 
          Whether it's optimizing ML workflows with <span className="text-colorGreen">Kubernetes</span> and <span className="text-colorGreen">Docker</span> or crafting secure APIs with <span className="text-colorGreen">Django</span> and <span className="text-colorGreen">Flask</span>, I love diving into the nitty-gritty details to deliver top-notch solutions. 
          Collaboration is key for me, and I enjoy working closely with <span className="text-colorGreen">cross-functional teams</span> to bring innovative ideas to life.
          </p>
          <p className="mt-5 text-gray-400"> {/* Light text */}
          Outside of work, you'll often find me <span className="text-colorMaroon">contributing to open-source projects</span>, or <span className="text-colorMaroon">sharing insights</span> at tech meetups. 
          I'm also a <span className="text-colorMaroon">retro emulation nerd</span> and play a lot of old classic games on several handhelds—currently playing The Legend of Zelda Series and Beyond Good and Evil. 
          I also have a deep passion for capturing the <span className="text-colorMaroon">beauty of the natural world</span> through my lens, finding joy and inspiration in every shot.
          </p>
        </div>
    </section>
  );
};

export default About;